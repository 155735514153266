// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amritseries-index-js": () => import("./../../../src/pages/amritseries/index.js" /* webpackChunkName: "component---src-pages-amritseries-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-project-index-js": () => import("./../../../src/pages/project/index.js" /* webpackChunkName: "component---src-pages-project-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-c-4-gt-page-js": () => import("./../../../src/templates/c4gt-page.js" /* webpackChunkName: "component---src-templates-c-4-gt-page-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-decluttered-page-js": () => import("./../../../src/templates/decluttered-page.js" /* webpackChunkName: "component---src-templates-decluttered-page-js" */),
  "component---src-templates-footer-page-js": () => import("./../../../src/templates/footer-page.js" /* webpackChunkName: "component---src-templates-footer-page-js" */),
  "component---src-templates-frameworks-page-js": () => import("./../../../src/templates/frameworks-page.js" /* webpackChunkName: "component---src-templates-frameworks-page-js" */),
  "component---src-templates-home-legacy-js": () => import("./../../../src/templates/home-legacy.js" /* webpackChunkName: "component---src-templates-home-legacy-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-joinus-page-js": () => import("./../../../src/templates/joinus-page.js" /* webpackChunkName: "component---src-templates-joinus-page-js" */),
  "component---src-templates-ksk-js": () => import("./../../../src/templates/ksk.js" /* webpackChunkName: "component---src-templates-ksk-js" */),
  "component---src-templates-media-page-js": () => import("./../../../src/templates/media-page.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-media-post-js": () => import("./../../../src/templates/media-post.js" /* webpackChunkName: "component---src-templates-media-post-js" */),
  "component---src-templates-old-case-study-js": () => import("./../../../src/templates/old-case-study.js" /* webpackChunkName: "component---src-templates-old-case-study-js" */),
  "component---src-templates-our-assets-page-js": () => import("./../../../src/templates/our-assets-page.js" /* webpackChunkName: "component---src-templates-our-assets-page-js" */),
  "component---src-templates-our-team-js": () => import("./../../../src/templates/our-team.js" /* webpackChunkName: "component---src-templates-our-team-js" */),
  "component---src-templates-partners-page-js": () => import("./../../../src/templates/partners-page.js" /* webpackChunkName: "component---src-templates-partners-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-project-domain-post-js": () => import("./../../../src/templates/project-domain-post.js" /* webpackChunkName: "component---src-templates-project-domain-post-js" */),
  "component---src-templates-project-post-js": () => import("./../../../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-templates-sushasan-page-js": () => import("./../../../src/templates/sushasan-page.js" /* webpackChunkName: "component---src-templates-sushasan-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-tgc-page-js": () => import("./../../../src/templates/tgc-page.js" /* webpackChunkName: "component---src-templates-tgc-page-js" */)
}

